body {
  padding: 0;
  margin: 0;
}

.landingPageBackground {
  /*background-size: 130%;*/
  background-size: 2500px;
  background-position: center -270px;
  height: 100vh;
  overflow-y: scroll;
}

@font-face {
  font-family: 'Hobeaux';
  src: url('./fonts/Hobeaux-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik One';
  src: url('./fonts/RubikOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@keyframes fadeEffect {
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

.wordCycle {
  animation: fadeEffect 3s infinite;
  display: inline;
}

.overallContainer {
  height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}

.signupButton:active {
  /*  Making button bigger on hover  */
  transform: scale(1.05) perspective(1px);
}

.loginButton:active {
  /*  Making button bigger on hover  */
  transform: scale(1.05) perspective(1px);
}

@media (hover: hover) and (pointer: fine) {
  .signupButton:hover { transform: scale(1.05) perspective(1px); }
  .loginButton:hover { transform: scale(1.05) perspective(1px); }
}
