.sidebar {
  transform: translateX(-166px);
  transition: transform 0.3s ease; /* Smooth transition effect */
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar.closed + .content {
  margin-left: 0;
}