@font-face {
  font-family: 'Assistant';
  src: url('./fonts/Assistant-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Assistant';
  src: url('./fonts/Assistant-SemiBold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Assistant';
  src: url('./fonts/Assistant-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Assistant';
  src: url('./fonts/Assistant-ExtraBold.otf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Assistant';
  src: url('./fonts/Assistant-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Assistant';
  src: url('./fonts/Assistant-ExtraLight.otf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/*@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_24pt-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_24pt-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
/*

TODO Confirm correct font denominations with designer


* {
  font-size: 14px; 
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}*/