.fade-in-component {
  opacity: 0;
  animation-name: fadeInAnimation;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: var(--fade-in-delay, 0.1s);
}

@keyframes fadeInAnimation {
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}